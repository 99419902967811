import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA , NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageComponent } from 'src/core/message/message.component';
import { LoaderComponent } from 'src/core/loader/loader.component';
import { ToastModule } from 'primeng/toast';
import { RestService } from 'src/core/rest.service';
import { CookieService } from 'ngx-cookie-service';
import { CommunicationService } from 'src/services/communication.service';
import { LoaderService } from 'src/core/loader/loader.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { SharedServiceModule } from '../services/sharedservice.module';
import { ToastrModule } from 'ngx-toastr';
import { LoginComponent } from './login';
import { MessageService } from 'primeng/api';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuModule } from './menu/menu.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpTranslateLoaderFactory } from 'src/core/i18n.loader';
import { I18nService } from 'src/core/i18n.service';
import { DatePipe } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap';


// import { MessageService } from 'src/core/message/message.service';
//  import { ToastrModule } from 'ngx-toastr';


@NgModule({
  declarations: [
    AppComponent,
    MessageComponent,
    LoaderComponent,
    LoginComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastModule,
    HttpClientModule,
    SharedModule,
    SharedServiceModule.forRoot(),
    ToastrModule.forRoot(),
    NgbDropdownModule,
    FormsModule,
    MenuModule,
    BsDatepickerModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpTranslateLoaderFactory,
          deps: [HttpClient]
      }
  }),
  ],
  providers: [
    LoaderService,
    MessageService,
    I18nService,
    RestService,
    CookieService,
    DatePipe,
    CommunicationService,
    // BsDatepickerConfig
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA],
})
export class AppModule { }


