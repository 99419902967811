import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'myDateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return null;

    // Parse the input date and adjust it to UTC without timezone shift
    const date = new Date(value);
    const utcDate = new Date(Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    ));

    // Format the date as dd-MMM-yyyy
    return super.transform(utcDate, 'dd-MMM-yyyy', 'UTC'); 
  }
}