import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login';
import { AuthGuard } from './auth.guard';


const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: '' },
  { path: '', component: LoginComponent },
  { path: 'forgotpassword', loadChildren: () => import('./forgotpassword/forgotpassword.module').then( (m) => m.ForgotPasswordModule)},
  { path: 'account', loadChildren: () => import('./user/profileupdate/account.module').then( (m) => m.AccountModule) ,canActivate: [AuthGuard]},
  { path: 'mytasks', loadChildren: () => import('./home/task.module').then( (m) => m.TaskModule) ,canActivate: [AuthGuard] },
  { path: 'home', loadChildren: () => import('./home/userhomecomponent/userhome.module').then( (m) => m.UserHomeModule) ,canActivate: [AuthGuard]  },
  { path: 'mysubmission', loadChildren: () => import('./home/submission/submission.module').then( (m) => m.SubmissionModule) ,canActivate: [AuthGuard]},
  { path: 'register', loadChildren: () => import('./register/register.module').then( (m) => m.RegisterModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then( (m) => m.AdminModule) ,canActivate: [AuthGuard]},
  { path: 'article', loadChildren: () => import('./workflow/workflow/workflow.module').then( (m) => m.WorkflowModule) ,canActivate: [AuthGuard]},
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
