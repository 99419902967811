<div class="app">
  <div class="auth_block">
    <div class="auth_content">
      <div class="auth_content_wrap">
        <!-- Header -->
        <header class="wrap_header">
          <div class="app_brand">
            <div class="brand_icon">
              <img src="../assets/img/pjc logo.png" alt="" />
            </div>
            <div class="brand_text">
              <h1>Philippine Journal of Cardiology</h1>
            </div>
          </div>
        </header>


        <div class="auth_form">
          <!-- loginId -->
          <div *ngIf="isUserAlredyExists=== false">
            <form [formGroup]="validateEmailform" (ngSubmit)="onValidateEmail(validateEmailform.value)">
              <div class="auth_form_head">
                <h2>Get Started.</h2>
                <!-- <p>Login to continue.</p> -->
              </div>
              <div class="auth_form_body">
                <div class="form-outline">
                  <div class="form-floating">
                    <input type="email" class="form-control" id="floatingInputEmail" placeholder="Enter your email id"
                      formControlName="Email" style="text-transform:lowercase" #textEmail>
                    <label for="floatingInputEmail">Enter your email id</label>
                  </div>
                  <div class="forms_error_info"
                    *ngIf="validateEmailform.controls['Email'].invalid && validateEmailform.controls['Email'].dirty">

                    <span class="error" *ngIf="validateEmailform.controls['Email'].errors.required">Email
                      required</span>
                    <span class="error"
                      *ngIf="validateEmailform.controls['Email'].errors.pattern && !validateEmailform.controls['Email'].errors.maxlength && !validateEmailform.controls['Email'].errors.minlength">Invalid
                      email id</span>
                  </div>

                </div>
                <button class="btn app_btn btn_secondary">Login /
                  Register</button>
                  
              </div>
            </form>
          </div>
          <!-- loginId -->

          <!--password OR OTP-->
          <div *ngIf="isUserAlredyExists === true">
            <form [formGroup]="validatePasswordform" (ngSubmit)="onValidatePassword(validatePasswordform.value)">
              <div class="auth_form_head">
                <h2>You are already registered!</h2>
                <p></p>
              </div>
              <div class="auth_form_body">
                <div class="form-outline">
                  <div class="form-floating">
                    <input class="form-control" id="floatingInputPassword" type="password"
                      placeholder="Enter your password" formControlName="Password" autocomplete="on"  #textPassword>
                    <label for="floatingInputPassword">Enter your password</label>
                  </div>

                  <div class="forms_error_info"
                    *ngIf="validatePasswordform.controls['Password'].invalid && validatePasswordform.controls['Password'].dirty">
                    <span class="error" *ngIf="validatePasswordform.controls['Password'].errors.required">Password
                      required! </span>
                    <span class="error" *ngIf="validatePasswordform.controls['Password'].errors.maxlength">Password
                      length exceeds </span>
                  </div>
                </div>
                <button
                  class="btn app_btn btn_secondary">Continue</button>

                <div class="or">
                  <span>Or</span>
                </div>

                <button (click)="loginUsingOtp()" class="btn app_btn btn-outline-dark" data-mdb-ripple-color="dark">Login
                  Using OTP</button>

                  <div class="mt-4"><a style="color: #d90845;" href="javascript:void(0)" (click)="onBack()">Back</a></div>  

              </div>
            </form>
          </div>
          <!--password OR OTP -->


          <!-- OTP Verification -->
          <div *ngIf="isShowOtpVerifyScreen">
            <div class="auth_form_head">
              <h2>Enter your 4 digit OTP.</h2>
              <p></p>
            </div>
            <div class="auth_form_body">
              <form [formGroup]="oneTimePasswordFormGroup"
                (ngSubmit)="onSubmitOtpFormGroup(oneTimePasswordFormGroup.value)">
                <div class="form-outline">
                  <div class="opt_grid box_4">
                    <input mdbInput id="otp0" (input)="otpInputs($event,0)" (paste)="otpPaste($event,0)"
                      (keyup)="otpOneCall($event,0)" placeholder="*" type="tel" pattern="\d" inputmode="numeric"
                      formControlName="input1" class="form-control otp" autofocus maxlength="1"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0,1)" />
                  
                    <input mdbInput id="otp1" type="tel" (input)="otpInputs($event,1)" (paste)="otpPaste($event,1)"
                      (keyup)="otpOneCall($event,1)" formControlName="input2" class="form-control otp"
                      placeholder="*" pattern="\d" inputmode="numeric" maxlength="1"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0,1)" />
                  
                    <input mdbInput id="otp2" type="tel" (input)="otpInputs($event,2)" placeholder="*"
                      (paste)="otpPaste($event,2)" (keyup)="otpOneCall($event,2)" formControlName="input3"
                      class="form-control otp" pattern="\d" inputmode="numeric" maxlength="1"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0,1)" />
                  
                    <input mdbInput id="otp3" type="tel" (input)="otpInputs($event,3)" placeholder="*"
                      (paste)="otpPaste($event,3)" (keyup)="otpOneCall($event,3)" formControlName="input4"
                      class="form-control otp" pattern="\d" inputmode="numeric" maxlength="1"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0,1)" />
                  </div>
                  
                  
                </div>
                <div class="forms_error_info" *ngIf="showInvalidOtp">
                  <i class="fa fa-close mr-1"></i>
                  <span>Please enter valid OTP</span>
                </div>
                <button  type="submit" id="otpSubmitBtn"
                  class="btn app_btn btn_secondary">Continue</button>
              </form>
            </div>
          </div>
          <!-- OTP Verification -->
        </div>


        <!-- Content -->



        <!-- Footer -->
        <footer class="wrap_footer">
          <div class="copyright">© Inlore Technologies Private Limited - All rights reserved.</div>
          <div class="powered_by">
            <p>
              Powered by
              <a href="#" target="_blank">
                <img src="../assets/img/scholarit_logo_white_rgb.png" style="background-color: #d53636;" />
              </a>
            </p>
          </div>
        </footer>
      </div>
    </div>
    <div class="auth_right_panel">
      <div class="right_panel_wrap">
        <div class="right_panel_content">
          <!-- <h1>Research Grant Application</h1> -->
            <img src="../../../assets/img/pjc logo-500w.png" alt="" /> 
        </div>
      </div>
    </div>
  </div>
</div>