<nav
	class="custom-navbar navbar navbar-expand-lg navbar-dark bg-dark mb-3 mt-0 p-0 mobile-padding-menu tab-padding-menu">
	<div class="container">
		<a class="navbar-brand">
			<img class="max-height-50" src="../assets/img/pjc heart logo.png">
		</a>
		<button class="navbar-toggler" type="button" (click)="toggleNavbar()">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
			<ul class="navbar-nav">
				<li class="nav-item" (click)="goToHome()"><a class="nav-link" *ngIf="isUserLoggedIn">Home</a></li>
				<li class="nav-item"><a class="nav-link" (click)="goToAllSubmission()"
						*ngIf="isChiefEditor || isAdministrator || adminAuthority || isEditor">All Submissions</a></li>
				<li class="nav-item"><a class="nav-link" (click)="onMySubmission()" *ngIf="isUserLoggedIn">My
						Submissions</a></li>
				<li class="nav-item" (click)="goToAllTasks()"><a class="nav-link" *ngIf="isUserLoggedIn">My Tasks</a>
				</li>
				<li class="nav-item"><a class="nav-link" (click)="onClickHelp()">Help</a></li>
			</ul>
			<ul class="navbar-nav  ml-auto">

				<span *ngIf="isUserLoggedIn" class="nav-item btn-group  color-menu-item" dropdown>
					<a href id="basic-link" class="nav-link" dropdownToggle (click)="false"
						aria-controls="basic-link-dropdown">Welcome {{displayName}}&nbsp;<i
							class="fa fa-caret-down"></i></a>
					<ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu bg-dark text-white" role="menu"
						aria-labelledby="basic-link">
						<li (click)="onUpdateProfile('/account/changepassword')" class="dropdown-item"><a
								class="dropdown-link" translate>menu.changePassword</a></li>
					</ul>
				</span>
				<li *ngIf="isUserLoggedIn" class="nav-item"><button class="btn btn-sm btn-danger btn-login"
						(click)="confirmLogoutModal()" id="logout"><i class="fa fa-sign-in"></i> Log Out</button></li>
			</ul>
		</div>
	</div>
</nav>
<!--logout Modal -->
<div class="modal fade" id="logoutModal" bsModal #logoutModal="bs-modal" tabindex="-1"
	aria-labelledby="logoutModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="saveDraftModalLabel">{{'menu.logoutModal.title' | translate }}</h5>
				<button type="button" class="btn-close" (click)="logoutModal.hide()" data-mdb-dismiss="modal"
					aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="static">
					<div class="static_block">
						{{'menu.logoutModal.body' | translate }}
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-sm btn-outline-secondary" data-mdb-dismiss="modal"
					(click)="logoutModal.hide()">{{'menu.logoutModal.cancel' | translate }}</button>
				<button (click)="logoutConfirm()" class="btn btn-sm btn-outline-primary">Ok</button>
			</div>
		</div>
	</div>
</div>
<!--/. logout Modal -->

<!--help Modal -->
<div class="modal fade" id="helpModal" bsModal #helpModal="bs-modal" tabindex="-1" aria-labelledby="helpModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="saveDraftModalLabel">Help</h5>
				<button type="button" class="btn-close" (click)="helpModal.hide()" data-mdb-dismiss="modal"
					aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="static">
					<div class="static_block">
						<h6>Philippine Journal of Cardiology (PJC) Journal System</h6>

						<p><strong><em>For queries, email to:</em></strong></p>

						<p><a href="mailto:pjc-support@scholarit.net">pjc-support@scholarit.net</a></p>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-sm btn-outline-primary" data-mdb-dismiss="modal"
					(click)="helpModal.hide()">Close</button>

			</div>
		</div>
	</div>
</div>
<!--/. help Modal -->


<!--Jury Scoreboard Modal -->
<div class="modal fade modal-xl" id="scoreBoardModal" bsModal #scoreBoardModal="bs-modal" tabindex="-1"
	aria-labelledby="scoreBoardModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="scoreBoardModalLabel">Review Score Board</h5>&nbsp; &nbsp;
				<!-- <button class="btn btn-sm btn-outline-primary" (click)="onRefreshJuryScore()">Summarize <i class="fa fa-refresh" aria-hidden="true"></i></button> -->
				<button type="button" class="btn-close" (click)="scoreBoardModal.hide()" data-mdb-dismiss="modal"
					aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="row mb-2">
					<div class="col text-right">
						<button class="btn btn-outline-info mr-2" (click)="onSummarizeReviewScores()">Summarize Review
							Scores </button>
					</div>

					<div class="col-2 text-right">
						<div class="dropdown">
							<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
								data-bs-toggle="dropdown" aria-expanded="false">
								Download
							</button>
							<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
								<li><a class="dropdown-item" (click)="OnDownloadExcelAsSummary()">Summary</a></li>
								<li><a class="dropdown-item" (click)="OnDownloadExcelAsRaw()">All data</a></li>
							</ul>
						</div>
					</div>
				</div>
				<table class="table table-bordered">
					<thead>
						<tr>
							<th scope="col" class="text-center">#</th>
							<th scope="col" class="text-center">Submission Id</th>
							<th scope="col" class="text-center">Count</th>
							<th scope="col" class="text-center">Trimmed Mean</th>
							<th scope="col" class="text-center">Mean</th>
							<th scope="col" class="text-center">Median</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let score of juryScores; let i = index">
							<th class="text-center" scope="row">{{i+1}}</th>
							<td class="text-center">{{score.submissionId}}</td>
							<td class="text-center">{{score.additionalInfo?.panelReview?.summary?.count | number :
								'1.0-2'}}</td>
							<td class="text-center">{{score.additionalInfo?.panelReview?.summary?.trimmean | number :
								'1.0-2' }}</td>
							<td class="text-center">{{score.additionalInfo?.panelReview?.summary?.mean | number :
								'1.0-2'}}</td>
							<td class="text-center">{{score.additionalInfo?.panelReview?.summary?.median | number :
								'1.0-2'}}</td>

						</tr>


					</tbody>
				</table>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-sm btn-outline-primary" data-mdb-dismiss="modal"
					(click)="scoreBoardModal.hide()">Close</button>

			</div>
		</div>
	</div>
</div>
<!--/. Jury Scoreboard Modal -->