import { RestService } from './../../core/rest.service';
import { Component, OnInit, AfterViewInit, ViewChild, NgZone, OnDestroy, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';


import { AppConfig } from '../../../config/app.config';
import { MessageService } from 'src/core/message/message.service';
import { CommunicationService } from 'src/services/communication.service';
import { AbstractService } from 'src/services/abstract.service';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { validateAllFormFields } from 'src/utils/validateform';
import { Location } from '@angular/common';
import { CustomValidators } from '../directives/custom.validator';
declare const gapi: any;
@Component({
  selector: 'login',
  styleUrls: ['./login.component.css'],
  templateUrl: './login.component.html',
  providers: [AuthService, RestService]
})

export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  public localState = { value: '' };
  // TO MAKE RESPONSIVE
  isCollapsed: boolean = true;
  lastUrl: any;
  public auth2: any;
  googleEmail: any;
  googleToken: any;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  loginRequired: string;
  loginMinLength: string;
  loginMaxLength: string;
  loginEmailInvalid: string;
  passwordRequired: string;
  passwordMaxLength: string;
  oneTimePasswordFormGroup: FormGroup;
  isShowOtpFormGroup: boolean = false;
  isUserNotRegistered: boolean = false;
  isRegistrationRequired: boolean = false;
  registerCheckboxValue: boolean = false;
  @ViewChild('registerModal', { static: false }) registerModal: ModalDirective;
  @ViewChild('textEmail', { static: false }) textEmail!: ElementRef;
  @ViewChild('textPassword', { static: false }) textPassword!: ElementRef;
  modalRef: any;
  conferenceShortName: string;
  eventShortName: string;
  isShowAlert = false;
  verification;
  isUserAlredyExists = false;
  validateEmailform: FormGroup;
  validatePasswordform: FormGroup;
  email;
  isShowOtpVerifyScreen;
  showInvalidOtp;
  otpValues = Array(4);
  clipData;
  private lastPath: string = '';
  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router, public modalService: BsModalService,
    public ngZone: NgZone, public cookieService: CookieService, public messagingService: MessageService,private location: Location,
    public translate: TranslateService, public communicationService: CommunicationService, public abstractService: AbstractService,) {
    // if (this.cookieService.get('userId')) {
    //   this.router.navigate(['/home']);
    // } @TODO

    this.lastUrl = this.cookieService.get('lastUrl');
    this.cookieService.delete('auth_token', '/');
    localStorage.clear()
    sessionStorage.clear();
    this.conferenceShortName = this.route.snapshot.queryParamMap.get('conference') ? this.route.snapshot.queryParamMap.get('conference') : 'IEEE-9';
    this.eventShortName = this.route.snapshot.queryParamMap.get('event') ? this.route.snapshot.queryParamMap.get('event') : 'event3';
  }
  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }
  public ngOnInit() {
    this.validateEmailform = new FormGroup({
      Email: new FormControl('', [
        CustomValidators.required,
        Validators.pattern(/^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,255}\.[a-zA-Z]{2,}$/),
      ]),
    });
    this.validatePasswordform = new FormGroup({
      Password: new FormControl('', [
        CustomValidators.required,
        Validators.maxLength(30),
      ]),
    });
    this.oneTimePasswordFormGroup = new FormGroup({
      input1: new FormControl('', [Validators.required]),
      input2: new FormControl('', [Validators.required]),
      input3: new FormControl('', [Validators.required]),
      input4: new FormControl('', [Validators.required]),
    });
    this.otpFromChanges();
  }

  setCookieAndNavigate(userId, adminAuthority, userName) {
    this.communicationService.setUserId(userId);
    this.communicationService.setAdminAuthority(adminAuthority);
    this.communicationService.setUserName(userName);
  }
  redirectTo() {
    if (this.lastUrl) {
      this.router.navigateByUrl(this.lastUrl);
      this.cookieService.delete('lastUrl');
    } else {
      
      this.router.navigate(['/home']);
      // this.router.navigate(['/admin/submissions']);
    }
  }

  ngOnDestroy() {

  }
  goToRegister() {
    this.router.navigate(['/register']);
  }
  onValidateEmail(values) {
    if (this.validateEmailform.valid) {
      const email = values.Email.toLowerCase();
      this.authService.userAlreadyExists(email).subscribe((res) => {
        if (res.body.data.isExists == true) {
          this.isUserAlredyExists = res.body.data.isExists;
          setTimeout(() => this.setFocus(), 0);
        } else {
          this.router.navigate(['/register'], {
            queryParams: { email },
          });
        }
      });
    } else {
      validateAllFormFields(this.validateEmailform);
    }
  }
  onValidatePassword(values) {
    if (this.validatePasswordform.valid) {
      const data = {
        email: this.validateEmailform.value.Email.toLowerCase(),
        password: values.Password
      };
      this.authService.loginUsingEmail(data).subscribe((res) => {
        if (res.body.data) {
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(res.body.data.token);
          localStorage.setItem('token', res.body.data.token);
          localStorage.setItem('userId', decodedToken.data.id);
          localStorage.setItem('adminAuthority', decodedToken.data.isAdmin);
          localStorage.setItem('userName', decodedToken.data.firstName);
          localStorage.setItem('displayName',`${decodedToken.data.salutation ? decodedToken.data.salutation : ''}  ${decodedToken.data.firstName} ${decodedToken.data.lastName ? decodedToken.data.lastName : ''}`);
           this.cookieService.set('auth_token',res.body.data.token);
          this.setCookieAndNavigate(decodedToken.data.id, decodedToken.data.isAdmin, decodedToken.data.firstName);
          this.redirectTo();
        }

      },
        (err) => { }
      );
    } else {
      validateAllFormFields(this.validatePasswordform);
    }
  }
  loginUsingOtp() {
    const journalId = AppConfig.JOURNAL_ID;
    const info = {
      email: this.validateEmailform.value.Email.toLowerCase(),
      journalId
    };
    this.authService.sendotp(info).subscribe((res) => {
      if (res.body.data) {
        this.email = res.body.data.email;
        this.verification = res.body.data.verification
        this.isUserAlredyExists = null;
        this.isShowOtpVerifyScreen = true;
      }
    });
   
  }
  otpFromChanges() {
    this.oneTimePasswordFormGroup.valueChanges.subscribe((value) => {
      if (this.oneTimePasswordFormGroup.valid) {
        this.showInvalidOtp = false;
      } 
    });
  }
  public onSubmitOtpFormGroup(value) {
    if (this.oneTimePasswordFormGroup.valid) {
      this.showInvalidOtp = false;
      const otpInfo = {
        email: this.email,
        otp: `${value.input1}${value.input2}${value.input3}${value.input4}`,
        verification: this.verification
      };
      this.authService.loginotp(otpInfo).subscribe((res) => {
        if (res.body.data) {
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(res.body.data.token);
          localStorage.setItem('token', res.body.data.token);
          localStorage.setItem('userId', decodedToken.data.id);
          localStorage.setItem('adminAuthority', decodedToken.data.isAdmin);
          localStorage.setItem('userName', decodedToken.data.firstName);
          localStorage.setItem('displayName',`${decodedToken.data.salutation ? decodedToken.data.salutation : ''}  ${decodedToken.data.firstName} ${decodedToken.data.lastName ? decodedToken.data.lastName : ''}`);
          this.setCookieAndNavigate(decodedToken.data.id, decodedToken.data.isAdmin, decodedToken.data.firstName);
          this.redirectTo();
        }
      });
    } else {
      this.showInvalidOtp = true;
    }
  }
  otpOneCall(event: KeyboardEvent, index: number) {
    const inputElement = event.target as HTMLInputElement;
    const formElement = inputElement.form;
  
    if (!formElement) return; // Safety check
  
    if (event.code === 'Backspace' && this.hasNoValue(index)) {
      if (index > 0) {
        (formElement['otp' + (index - 1)] as HTMLInputElement).focus();
      }
    } else if (inputElement.value !== '') {
      if (index < 3) {
        (formElement['otp' + (index + 1)] as HTMLInputElement).focus();
      } else {
        // Move focus to the submit button when 4th OTP digit is entered
        this.focusSubmitButton();
      }
    }
  
    this.otpValues[index] = inputElement.value;
  }
  

  hasNoValue(index) {
    if (this.otpValues[index] || this.otpValues[index] === 0) return false;

    return true;
  }
  otpInputs(event, index) {
    if (event.target.value > 10) {
      event.target.value = event.target.value % 10;
    }
  }
  otpPaste(event, index) {
    event.preventDefault();
    this.clipData = event.clipboardData.getData('text/plain').split('');
    this.filldata(event.target.form, index);
  }

  filldata(form, index) {
    for (let i = index; i < 6; i++) {
      form['otp' + i].value = this.clipData.shift();
    }
  }
  onBack() {
    this.isUserAlredyExists = false;
    setTimeout(() => this.setFocus(), 0);
  }
  ngAfterViewInit() {
    this.setFocus();
  }
  private setFocus() {
    if (this.isUserAlredyExists == false) {
      this.textEmail?.nativeElement.focus();
    } else if (this.isUserAlredyExists == true) {
      this.textPassword?.nativeElement.focus();
    }
  }
  focusSubmitButton() {
    setTimeout(() => {
      const submitBtn = document.getElementById("otpSubmitBtn") as HTMLButtonElement;
      if (submitBtn) {
        submitBtn.focus(); // Move focus to submit button
      }
    }, 100); // Delay to ensure input processing is complete
  }
}
