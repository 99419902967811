import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Access query parameters from the ActivatedRouteSnapshot
    const queryParamToken = next.queryParamMap.get('token');
    const token = localStorage.getItem('token');

    // Check if the token is present either in local storage or as a query parameter
    if (token || queryParamToken) {
      return true; // Allow access to the route
    } else {
      // Redirect to the login page if neither token is found
      this.router.navigate(['/']);
      return false; // Prevent access to the route
    }
  }
}
